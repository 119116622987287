import React from 'react';
import styled, { CSSProp } from 'styled-components';

interface StyledFlexProps {
    block?: boolean
    flexDirection?: string;
    justify?: string;
    align?: string;
}

const StyledFlex = styled.div<StyledFlexProps>`
    display: ${({ block }: { block?: boolean }): CSSProp => (block && 'block') || 'flex'};
    flex-direction: ${({ flexDirection }: { flexDirection?: string }) => flexDirection || ''};
    justify-content: ${({ justify }: { justify?: string }) => justify || 'center'};
    align-items: ${({ align }: { align?: string }) => align || ''};
`;

interface Props {
    children: JSX.Element | JSX.Element[] | string | React.ReactNode;
    flexDirection?: string;
    justify?: string;
    align?: string;
    block?: boolean;
    as?: 'div' | 'section' | 'article';
}

export const Flex = ({
    children,
    flexDirection,
    justify,
    align,
    block,
    as,
    ...props
}: Props): JSX.Element => (
        <StyledFlex
            {...props}
            as={as}
            block={block}
            flexDirection={flexDirection}
            justify={justify}
            align={align}
        >
            {children}
        </StyledFlex>
    )

export const Section = ({
    children,
    flexDirection,
    justify,
    align,
    block,
    as,
    ...props
}: Props): JSX.Element => (
        <StyledFlex
            {...props}
            as={as}
            block={block}
            flexDirection={flexDirection}
            justify={justify}
            align={align}
        >
            {children}
        </StyledFlex>
    )

export const Article = ({
    children,
    flexDirection,
    justify,
    align,
    block,
    as,
    ...props
}: Props): JSX.Element => (
        <StyledFlex
            {...props}
            as={as}
            block={block}
            flexDirection={flexDirection}
            justify={justify}
            align={align}
        >
            {children}
        </StyledFlex>
    )