import React from 'react';
import styled from 'styled-components';
import { Container } from '../../../view/Containers/Containers';
import { HeaderWithLines } from '../../../view/TextForms/HeaderWithLines';
import { Flex } from '../../../view/Flex';
import { Separator } from '../../../view/Separators';
import { ImposedBoxes } from '../../../view/ImposedBoxes/ImposedBoxes';
import { H3 } from '../../../view/Header';
import { Paragraph } from '../../../view/Paragraph';
import { enclousuresBalcony } from '../../ImageStorage/enclosuresBalcony';

const ProcessHeader = styled(H3)`
  text-align: center;
  font-size: 30px;
  margin-bottom: 16px;
`;

const ListItemP = styled(Paragraph)`
  font-weight: bold;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ListStyle = styled(Paragraph)`
  font-size: 44px;
  color: #64B5F6;
  margin-right: 20px;
  font-weight: bold;
`;

const ListItemGreyOutText = styled.span`
  color: #9E9E9E;
  font-style: italic;
  font-weight: normal;
`;

const LineTopicSeparator = styled.div`
  height: 2px;
  width: 50%;
  background: #64B5F6;
`

export const Process = (): JSX.Element => {
  return (
    <Container
      paddingVert
      paddingHori
      as='section'
    >
      <Flex>
        <HeaderWithLines>
          Co dalej?
        </HeaderWithLines>
      </Flex>
      <Separator />
      <ImposedBoxes
        image={enclousuresBalcony[1]}
      >
        <Flex block>
          <ProcessHeader>
            Jestem zainteresowany!
          </ProcessHeader>
          <Flex justify="center">
            <LineTopicSeparator />
          </Flex>
          <List>
            <ListItem>
              <ListStyle>
                1
              </ListStyle>
              <ListItemP>
                Skontaktuj się z&nbsp;nami - zadzwoń do nas lub napisz maila. Odpisujemy na wszystkie wiadomości.
              </ListItemP>
            </ListItem>
            <ListItem>
              <ListStyle>
                2
              </ListStyle>
              <ListItemP>
                Obmiar – bezpłatny na w&nbsp;woj. mazowieckim. Wytłumaczmy Ci wszystko i&nbsp;doradzimy najlepsze rozwiązanie. Spotkanie nie zobowiązuje Cię do niczego.
                &nbsp;
                <ListItemGreyOutText>
                  Poza woj. mazowieckim obowiązuje opłata za dojazd – która jest zwracana, gdy zdecdujesz się na realizację.
                </ListItemGreyOutText>
              </ListItemP>
            </ListItem>
            <ListItem>
              <ListStyle>
                3
              </ListStyle>
              <ListItemP>
                Montaż u Ciebie.
              </ListItemP>
            </ListItem>
          </List>
        </Flex>
      </ImposedBoxes>
    </Container>
  );
}