import React, { useState, useCallback } from 'react';
import styled, { CSSProp, css } from 'styled-components';
import { Paragraph } from './Paragraph';
import { Icon } from './Icon';
import { Modal } from './Modal/Modal';
import { MediumShadowStyle } from './ShadowBox/ShadowBox';
import { media } from './media/media';

const GalleryFlex = styled.div`
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;

  @media(max-width: ${media.small}) {
    grid-template-columns: 50% 50%;
  }
`;

const GalleryPhoto = styled.img<{ img?: string }>`
  position: relative;
  max-height: 80%;
`;

const ChangeGalleryButtons = styled.button<{ left?: boolean; right?: boolean; }>`
  position: absolute;
  color: #bdbdbd;
  height: 100%;
  width: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  color: #bdbdbd;
  outline: none;
  width: 50px;
  padding: 0 50px;
  cursor: pointer;

  i {
    color: white;
    font-size: 60px;
  }

  &:hover {
    i {
      color: #64B5F6;
    }
  }

  ${({ left }): CSSProp => (left &&
    css`
     left: 0; 
  `) || ''}

  ${({ right }): CSSProp => (right &&
    css`
     right: 0; 
  `) || ''}
`;

const PhotoBoxInner = styled.article`
  display: none;
  align-items: flex-end;
  justify-content: flex-end;
  height: 20%;
  padding: 0 20px;
`;

const PhotoBoxText = styled(Paragraph)`
  color: #fff;
  font-size: 14px;
`;

const PhotoBoxIcon = styled(Icon)`
  height: 80%;
  padding-top: 50px;
  font-size: 90px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const PhotoBox = styled.div<{ src?: string, isShort: boolean; }>`
  height: 260px;
  background: url(${({ src }) => src || ''});
  background-size: cover;
  margin: 5px;
  border-radius: 5px;
  grid-column-end: ${({ isShort }) => (isShort && 'span 1') || 'span 2'};
  ${MediumShadowStyle}
  ${PhotoBoxIcon} {
    display: none;
  }

  @media(max-width: ${media.small}) {
    grid-column-end: span 1;
  }

  &:hover {
    background: linear-gradient(0deg, rgba(0,0,0,0.6) 1%, rgba(0,0,0,0.1) 100%),
    url(${({ src }) => src || ''});
    background-size: cover;
    display: block;
    ${PhotoBoxInner},
    ${PhotoBoxIcon} {
      cursor: pointer;
      display: flex;
    }
  }
`;

export const Gallery = ({
  galleryPhotos,
  galleryLenght,
}: {
  galleryPhotos: string[]
  galleryLenght: number;
}): JSX.Element => {
  const [selectedPhoto, setSelectedPhoto] = useState<number | undefined>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClickOpen = (index: number): void => {
    setSelectedPhoto(index);
    setIsOpen(true);
  }

  const onClickClose = useCallback(() => setIsOpen(false), [isOpen]);

  if (!galleryPhotos) {
    return <></>;
  }

  return (
    <GalleryFlex>
      {galleryPhotos.map((photo, index) =>
        <PhotoBox key={`${photo}_${index}`} isShort={!((index + 1) % 4) || index === 1} onClick={() => onClickOpen(index)} src={photo}>
          <PhotoBoxIcon>
            zoom_in
          </PhotoBoxIcon>
          <PhotoBoxInner>
            <PhotoBoxText>
              <>
                {index + 1} / {galleryLenght}
              </>
            </PhotoBoxText>
          </PhotoBoxInner>
        </PhotoBox>
      )}
      {(isOpen && selectedPhoto !== undefined)
        && (
          <Modal onClick={() => onClickClose()} key={selectedPhoto}>
            {selectedPhoto > 0 ? (
              <ChangeGalleryButtons left onClick={(e): void => { e.stopPropagation(); setSelectedPhoto(selectedPhoto - 1) }}>
                <Icon>
                  keyboard_arrow_left
                </Icon>
              </ChangeGalleryButtons>
            ) : <></>}
            <GalleryPhoto src={galleryPhotos[selectedPhoto]} />
            {selectedPhoto < galleryPhotos.length - 1 ? (
              <ChangeGalleryButtons right onClick={(e): void => { e.stopPropagation(); setSelectedPhoto(selectedPhoto + 1) }}>
                <Icon>
                  keyboard_arrow_right
                </Icon>
              </ChangeGalleryButtons>
            ) : <></>}
          </Modal>)}
    </GalleryFlex>
  )
}