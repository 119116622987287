import React from 'react';
import styled from 'styled-components';

const StyledH = styled.span`
    font-family: 'Noto Sans', sans-serif;
    font-weight: bold;
    color: #333;
`

interface Props extends React.HtmlHTMLAttributes<HTMLElement> {
    children: JSX.Element | JSX.Element[] | string | React.ReactNode;
    as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

export const Header = ({
    children,
    as,
    ...props
}: Props) => <StyledH as={as || 'h2'} {...props}>{children}</StyledH>

export const H1 = ({ children, ...props }: Props) => (
    <StyledH as="h1" className={props.className} {...props}>
        {children}
    </StyledH>
)

export const H2 = ({ children, ...props }: Props) => (
    <StyledH as="h2" className={props.className} {...props}>
        {children}
    </StyledH>
)

export const H3 = ({ children, ...props }: Props) => (
    <StyledH as="h3" className={props.className} {...props}>
        {children}
    </StyledH>
)

export const H4 = ({ children, ...props }: Props) => (
    <StyledH as="h4" className={props.className} {...props}>
        {children}
    </StyledH>
)