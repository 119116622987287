import React from 'react';
import styled from 'styled-components';
import { Flex } from './Flex';
import { Icon } from './Icon';
import { Paragraph } from './Paragraph';

interface IconAttribiute {
  icon: string;
  text: string;
  subText?: string;
  color?: string;
}

interface IconAttribiutesProps {
  iconAttributes: IconAttribiute[];
}

const IconAttributesContainer = styled(Flex)`
  margin: 0 20px;
  width: 250px;

  @media(max-width: 860px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
  }
`;

const IconAttributesIcon = styled(Icon)`
  margin: 0 10px;
  user-select: none;
`;

const IconAttributesText = styled(Paragraph)`
  user-select: none;
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: bold;

  @media(max-width: 860px) {
    text-align: center;
  }
`;

const IconAttributesSubText = styled(Paragraph)`
  user-select: none;
  font-size: 14px;
  margin: 0;

  @media(max-width: 860px) {
    text-align: center;
  }
`;

const IconAttributesWrapper = styled(Flex)`
  @media(max-width: 860px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const IconAttributes = ({ iconAttributes }: IconAttribiutesProps) => (
  <IconAttributesWrapper>
    {iconAttributes.map((attribiute: IconAttribiute): JSX.Element => (
      <IconAttributesContainer key={attribiute.text} align="flex-start" justify="flex-start">
        <IconAttributesIcon color={attribiute.color} fontSize="50px">
          {attribiute.icon}
        </IconAttributesIcon>
        <Flex block>
          <IconAttributesText>
            {attribiute.text}
          </IconAttributesText>
          {attribiute.subText && (
            <IconAttributesSubText>
              {attribiute.subText}
            </IconAttributesSubText>
          )}
        </Flex>
      </IconAttributesContainer>
    ))}
  </IconAttributesWrapper>
)