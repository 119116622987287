import React, { useLayoutEffect } from 'react';
import { Navbar } from '../../view/Navbar/Navbar';
import { ContactSection } from '../../view/ContactSection';
import { Separator } from '../../view/Separators';
import { Container } from '../../view/Containers/Containers';
import { Flex } from '../../view/Flex';
import { HeaderWithLines } from '../../view/TextForms/HeaderWithLines';
import { Gallery } from '../../view/Gallery';
import { SubPagesBanner } from '../../view/SubPagesBanner/SubPagesBanner';
import { AppRouting } from './../../components/interface.d';
import { useLocation } from 'react-router';
import { seeMore } from './../../components/ContentData';
import { Card } from './../Card/Card';
import styled from 'styled-components';
import { media } from './../media/media';

const GridFlex = styled.div`
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;

  @media(max-width: ${media.small}) {
    grid-template-columns: 100%;
  }
`;

export const SubPageWrapper = ({ navElements, logo, pageContent }: AppRouting): JSX.Element => {

  const location = useLocation();
  useLayoutEffect((): void => {
    window.scrollTo(0, 0);
  }, []);

  const filteredSeeMore = seeMore.filter((more) => location.pathname !== more.linkDetails.link)

  return (
    <>
      <Navbar navElements={navElements} logo={logo} />
      <SubPagesBanner
        header={pageContent.bannerHeader}
        text={pageContent.bannerSubHeader}
        img={pageContent.mainImage}
      />
      <ContactSection />
      <Separator />
      <Separator />
      <Container>
        <Flex>
          <HeaderWithLines>
            Galeria
          </HeaderWithLines>
        </Flex>
        <Gallery
          galleryLenght={pageContent.photos.length}
          galleryPhotos={pageContent.photos}
        />
      </Container>
      <Separator />
      <Container>
        <Flex>
          <HeaderWithLines>
            Zobacz też
          </HeaderWithLines>
        </Flex>
        <GridFlex>
          {filteredSeeMore.map((more) => (
            <Card
              key={more.linkDetails.link}
              img={more.image}
              link={more.linkDetails.link}
              linkText={more.linkDetails.name}
            />
          ))}
        </GridFlex>
      </Container>
      <Separator />
    </>
  )
}
