import React from 'react';
import styled from 'styled-components';
import { Flex } from './../Flex';
import { ButtonShadowStyle } from './../ShadowBox/ShadowBox';
import { Link } from 'react-router-dom';
import { H4 } from './../Header';

interface Props {
  img: string;
  link: string;
  linkText: string | JSX.Element;
}

const CardLinkHeader = styled(H4)`
  font-weight: bold;
  margin: 0;
  &:hover {
    color: rgb(100, 181, 246);
  }
`;

const CardWrapper = styled(Link)`
  border-radius: 10px;
  margin: 10px;
  text-decoration: none;
  ${ButtonShadowStyle}
  
  &:hover {
    ${CardLinkHeader} {
      color: rgb(100, 181, 246);
    }
  }
`;

const CardImage = styled.div<{ img: string }>`
  border-radius: 5px;
  background-image: url(${({ img }) => (img) || ''});
  background-size: cover;
  width: 100%;
  height: 180px;
`;

const CardInfoWrapper = styled.article`
  padding: 10px;
  display: flex;
  justify-content: flex-start;
`;

export const Card = ({ img, link, linkText }: Props): JSX.Element => (
  <CardWrapper to={link}>
    <CardImage img={img} />
    <CardInfoWrapper>
      <Flex justify="flex-start">
        <CardLinkHeader>
          {linkText}
        </CardLinkHeader>
      </Flex>
    </CardInfoWrapper>
  </CardWrapper>
)