import img1 from '../../img/others/img1.jpg';
import img2 from '../../img/others/img2.jpg';
import img3 from '../../img/others/img3.jpg';
import img4 from '../../img/others/img4.jpg';
import img6 from '../../img/others/img6.jpg';
import img7 from '../../img/others/img7.jpg';
import img8 from '../../img/others/img8.jpg';
import img9 from '../../img/others/img9.jpg';
import img10 from '../../img/others/img10.jpg';
import img11 from '../../img/others/img11.jpg';
import img12 from '../../img/others/img12.jpg';

export const others = [
  img1,
  img2,
  img3,
  img4,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
]