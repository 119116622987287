import React, { Fragment } from 'react';
import styled from 'styled-components';
import { H3 } from '../Header';
import { Container } from './../Containers/Containers';
import { Flex } from './../Flex';
import { Separator } from './../Separators';
import { footerLinks, NavigationElements } from './../../components/DataSheets';
import { Link } from 'react-router-dom';
import { Paragraph } from '../Paragraph';
import { FooterShadow } from '../ShadowBox/ShadowBox';
import { Icon } from './../Icon';
import { media } from './../media/media';

const FooterWrapper = styled.div`
  background-color: #fff;
  padding-bottom: 80px;
  ${FooterShadow}
`;

const FooterItemFlex = styled(Flex)`
  width: 33%;
 @media (max-width: ${media.small}) {
   width: 100%;
 }
`;

const LinkListSingleItem = styled.div`
  padding: 0 0 10px 0px;
`;

const FooterHeaderIcon = styled(Icon)`
  margin-right: 0.3rem;
`;

const FooterItemWprapper = styled(Flex)`
  @media(max-width: ${media.small}) {
    flex-direction: column;
    width: 100%;
    padding-left: 1em;
    box-sizing: border-box;
  }
`;

const ListItemLink = styled(Link)`
  font-size: 14px;
  font-weight: bold;
  font-family: 'Noto Sans', sans-serif;
  text-decoration: none;
  color: #333;

  &:hover {
    color: #58a5f0;
    text-decoration: underline;
  }
`;

const ContactP = styled(Paragraph)`
  margin: 5px 0;
`;

export const Footer = () => (
  <>
    <Separator />
    <Separator />
    <FooterWrapper>
      <Container>
        <FooterItemWprapper>
          <FooterItemFlex flexDirection="column">
            <Flex justify="flex-start" align="center">
              <FooterHeaderIcon>
                link
              </FooterHeaderIcon>
              &nbsp;
              <H3>
                Linki
              </H3>
            </Flex>
            {footerLinks.map((menu) => (
              <Fragment key={menu.link + menu.name}>
                <LinkListSingleItem>
                  <ListItemLink to={menu.link}>
                    {menu.name}
                  </ListItemLink>
                </LinkListSingleItem>
              </Fragment>
            ))}
          </FooterItemFlex>
          <FooterItemFlex block>
            <Flex justify="flex-start" align="center">
              <FooterHeaderIcon>
                contacts
              </FooterHeaderIcon>
              &nbsp;
              <H3>
                Kontakt
              </H3>
            </Flex>
            <Flex justify="flex-start" align="center">
              <FooterHeaderIcon fontSize="18px">
                phone
              </FooterHeaderIcon>
              &nbsp;
              <ContactP>
                +48 504 107 384
              <br />
              </ContactP>
            </Flex>
            <Flex justify="flex-start" align="center">
              <FooterHeaderIcon fontSize="18px">
                mail
              </FooterHeaderIcon>
              &nbsp;
              <ContactP>
                <a href="mailTo:biuro@budmag.pl">biuro@budmag.pl</a>
              </ContactP>
            </Flex>
          </FooterItemFlex>
          <FooterItemFlex block>
            <Flex justify="flex-start" align="center">
              <FooterHeaderIcon>
                business
              </FooterHeaderIcon>
              &nbsp;
              <H3>
                Dane Firmy
              </H3>
            </Flex>
            <Paragraph>
              BUDMAG Usługi budowlano-ślusarskie
              <br />
              Urszula Dziub
            </Paragraph>
            <Paragraph>
              Husarska 17/4
              <br />
              05- 120 Legionowo
              <br />
              NIP: 734-167-86-00
            </Paragraph>
          </FooterItemFlex>
        </FooterItemWprapper>
      </Container>
    </FooterWrapper >
  </>
)