import img1 from '../../img/roofs-balcony/img1.jpeg';
import img2 from '../../img/roofs-balcony/img2.jpg';
import img3 from '../../img/roofs-balcony/img3.JPG';
import img4 from '../../img/roofs-balcony/img4.jpg';
import img4_1 from '../../img/roofs-balcony/img4_1.jpg';
import img4_2 from '../../img/roofs-balcony/img4_2.jpg';
import img4_3 from '../../img/roofs-balcony/img4_3.jpg';
import img5 from '../../img/roofs-balcony/img5.jpg';
import img6 from '../../img/roofs-balcony/img6.jpg';
import img7 from '../../img/roofs-balcony/img7.jpg';
import img8 from '../../img/roofs-balcony/img8.jpg';
import img9 from '../../img/roofs-balcony/img9.jpg';
import img10 from '../../img/roofs-balcony/img10.jpg';
import img11 from '../../img/roofs-balcony/img11.jpg';
import img12 from '../../img/roofs-balcony/img12.jpg';
import img13 from '../../img/roofs-balcony/img13.jpg';
import img14 from '../../img/roofs-balcony/img14.jpg';

export const roofsBalcony = [
  img1,
  img2,
  img3,
  img4,
  img4_1,
  img4_2,
  img4_3,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
]