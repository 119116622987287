import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { H2 } from './../Header';

const HeaderLineBase = css`
        content: '';
        position: absolute;
        width: 100px;
        height: 2px;
        background: #333;
        top: 50%;
`;

const ServicesPageHeader = styled(H2)`
  margin: 0 auto;
  letter-spacing: 2;
  text-align: center;
  margin-bottom: 38px;
  margin-top: 38px;
  font-size: 30px;
  letter-spacing: 10px;
  text-transform: uppercase;
  position: relative;

  @media(min-width: 860px) {
    &::before {
      ${HeaderLineBase}
      left: -125px;
    }

    &::after {
      ${HeaderLineBase}
      right: -125px;
    }
  }
`;

export const HeaderWithLines = ({ children }: HTMLAttributes<HTMLElement>): JSX.Element => (
  <ServicesPageHeader>
    {children}
  </ServicesPageHeader>
)