import React from "react";
import styled from 'styled-components';

const P = styled.p`
  font-family: 'Noto Sans', sans-serif;
  color: #333;
  font-size: 1rem;
`;

interface Props {
  children: string | JSX.Element | JSX.Element[] | React.ReactNode;
}

export const Paragraph = ({ children, ...props }: Props) => <P {...props}>{children}</P>
