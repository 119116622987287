import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 1;
  top: 0%;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Modal = ({ children, onClick, ...props }: Modal.ModalChildren): any => {
  const ModalInHTML = document.getElementById('modal') as HTMLElement;
  return ReactDOM.createPortal(
    (
      <ModalWrapper {...props} onClick={onClick ? () => onClick() : (): void => { }}>
        {children}
      </ModalWrapper>
    ),
    ModalInHTML,
  )
};