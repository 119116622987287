
import styled from 'styled-components';

export const Separator = styled.div`
    height: 38px;
    width: 100%;
`;

export const LineSeparator = styled.hr`
    height: 2px;
    width: 10%;
    margin: 0;
    border: none;
    background-color: #64B5F6;
`;