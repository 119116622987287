import React from 'react';
import styled from 'styled-components';
import { H1 } from '../../../view/Header';
import { Paragraph } from '../../../view/Paragraph';
import { Banner, WelcomeSectionText } from '../../../view/Banner';
import { enclousuresTerraces } from './../../ImageStorage/enclousuresTerraces';

const WelcomeHeader = styled(H1)`
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    margin-bottom: 25px;
    line-height: 1.2;
    letter-spacing: 10px;
`;

const WelcomeParagraph = styled(Paragraph)`
    color: #fff;
`;

export function WelcomeSection(): JSX.Element {
  return (
    <Banner
      full
      shadow
      img={enclousuresTerraces[0]}
    >
      <WelcomeSectionText>
        <WelcomeHeader>
          <>
            Zabudowa i&nbsp;zadaszenia tarasów, balkonów, ogrody zimowe
            <br />
            i&nbsp;więcej
          </>
        </WelcomeHeader>
        <WelcomeParagraph>
          <>
            Wykonujemy realizacje odpowiadając na potrzeby klientów, wspólnie dobierając rozwiązania do bryły budynku.
            Do pracy wykorzystujemy materiały najlepszej jakości.
            Konstrukcje wykonujemy ze stali, aluminium i&nbsp;drewna.
            Montujemy systemy okien przesuwnych. Zadaszenia wykonujemy z&nbsp;poliwęglanu: litego i&nbsp;komorowego, różnych grubości.
            Nie ma dla nas rzeczy niemożliwych - sprawdź nas.
          </>
        </WelcomeParagraph>
      </WelcomeSectionText>
    </Banner>
  )
}