import React from 'react';
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import '../CSSRules/normalize.css';
import { Home } from './Home/Home';
import { NavigationElements } from './DataSheets';
import Logo from '../img/Logo.png';
import { Footer } from '../view/Footer/Footer';
import { SubPageWrapper } from '../view/SubPageWrapper/SubPageWrapper';
import {
  EnclousuresTerracesData,
  EnclousuresBalconyData,
  RoofsBalconyData,
  RoofsData,
  OthersData,
  RoofsTerracesData,
} from './ContentData';
import { ContactPage } from './Contact/Contact';

export function App(): JSX.Element {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home navElements={NavigationElements} logo={Logo} />
        </Route>
        <Route exact path="/enclosures/balcony">
          <SubPageWrapper
            key="EnclousuresBalconyData"
            navElements={NavigationElements}
            logo={Logo}
            pageContent={EnclousuresBalconyData}
          />
        </Route>
        <Route exact path="/enclosures/terraces">
          <SubPageWrapper
            key="EnclousuresTerracesData"
            navElements={NavigationElements}
            logo={Logo}
            pageContent={EnclousuresTerracesData}
          />
        </Route>
        <Route exact path="/roofs">
          <SubPageWrapper
            key="RoofsData"
            navElements={NavigationElements}
            logo={Logo}
            pageContent={RoofsData}
          />
        </Route>
        <Route exact path="/roofs/balcony">
          <SubPageWrapper
            key="RoofsBalconyData"
            navElements={NavigationElements}
            logo={Logo}
            pageContent={RoofsBalconyData}
          />
        </Route>
        <Route exact path="/roofs/terraces">
          <SubPageWrapper
            key="RoofsTerracesData"
            navElements={NavigationElements}
            logo={Logo}
            pageContent={RoofsTerracesData}
          />
        </Route>
        <Route exact path="/others">
          <SubPageWrapper
            key="RoofsTerracesData"
            navElements={NavigationElements}
            logo={Logo}
            pageContent={OthersData}
          />
        </Route>
        <Route exact path="/contact">
          <ContactPage
            navElements={NavigationElements}
            logo={Logo}
          />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}
