import React from 'react';
import { Container } from './../../../view/Containers/Containers';
import { Flex } from './../../../view/Flex';
import { HeaderWithLines } from './../../../view/TextForms/HeaderWithLines';
import { IconAttributes } from './../../../view/IconAttributes';
import { Separator } from './../../../view/Separators';
import { howWeDoIt } from '../../DataSheets';
import { materials } from './../../DataSheets';
import { media } from './../../../view/media/media';
import styled from 'styled-components';

const IconAttributesSeparator = styled(Separator)`
  @media(max-width: ${media.small}) {
    display: none;
  }
`;

export const InfoBanner = (): JSX.Element => (
  <Container>
    <Flex>
      <HeaderWithLines>
        Co powinieneś wiedzieć
      </HeaderWithLines>
    </Flex>
    <IconAttributes iconAttributes={howWeDoIt} />
    <IconAttributesSeparator />
    <IconAttributes iconAttributes={materials} />
    <Separator />
  </Container>
)