import React from 'react';
import styled from 'styled-components';
import { MediumShadowStyle } from './../ShadowBox/ShadowBox';
import { media } from './../media/media';

const IposedBoxesImg = styled.div<{ image?: string }>`
  ${MediumShadowStyle}
  background: linear-gradient(90deg, rgba(0,0,0,0.7) 33%, rgba(0,0,0,0.3) 66%, rgba(0,0,0, 0) 99%),
  url(${({ image }) => image || ''}),
  #bdbdbd;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px;
  display: flex;
  justify-content: center;
  border-radius: 10px;

  @media(max-width: ${media.small}) {
    padding: 0;
  }
`;

const ImposedBoxContent = styled.article`
  background: #fff;
  width: 540px;
  height: 540px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  box-sizing: border-box;
  border-radius: 10px;
  ${MediumShadowStyle}

  @media(max-width: ${media.small}) {
    padding: 0 20px;
    width: 100%;
    height: auto;
  }
`;

interface Props extends React.HTMLAttributes<HTMLElement> {
  image: string;
}

export const ImposedBoxes = ({ image, children }: Props): JSX.Element => {
  return (
    <IposedBoxesImg image={image}>
      <ImposedBoxContent>
        {children}
      </ImposedBoxContent>
    </IposedBoxesImg>
  )
} 